


























































import useMisc from "@/use/misc";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  props: {
    currency: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const state = reactive({
      headers: [
        {
          value: "index",
          sortable: false,
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
        },
        {
          value: "name",
          sortable: false,
          text: root.$tc("layout.misc.groupName"),
        },
        {
          value: "isEnabled",
          text: root.$tc("layout.misc.state"),
          sortable: false,
          width: 1,
          align: "right",
        },
      ],
      items: [],
      loading: false,
      loaded: false,
      total: 0,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
    });

    const { getDiscountType, getChoiceType } = useMisc({ root });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { sortBy, sortDesc } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/additional-service-group`, {
          params: { sortBy, sortDesc },
        })
        .then(({ data: { additionalServiceGroups, total } }) => {
          state.items = additionalServiceGroups;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const deleteGroup = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/additional-service-group/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const deleteService = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/additional-service/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const showParticipants = (id: string) => {
      goBack("panel.event.view.participant.search", { service: id });
    };

    return {
      state,
      fetchData,
      deleteGroup,
      deleteService,
      getDiscountType,
      getChoiceType,
      showParticipants,
    };
  },
});
